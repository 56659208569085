@import '../../../colors';

.checkbox {
  color: rgba(21, 22, 26, 0.55);
}
.checkbox-green {
  .icon {
    color: rgba(0, 133, 78, 1);
  }

  .text {
    color: rgba(18, 22, 33, 0.75);
  }
}

.name-container {
  border-radius: 16px;
  padding: 16px;
  border: 1px solid $gray-border;
  margin-bottom: 16px;

  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin: 0;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 17.5px;
    text-align: left;
  }
}
