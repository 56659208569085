.input-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.forgot-password {
  position: absolute;
  right: 0;
  padding: 10px 0 0 0;
  transform: translateY(-100%);
  border: none;
  background: none;
  cursor: pointer;
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  color: #2a60ea;
}
